<!--System: Whistle it 
  Module: Super Admin
  Developer: Gulraiz Ahmed 
  Edited:Hassan Ali
  Date: 17-06-2021 
  Organization: Whistle it 
  Purpose: The purpose of this component is to display Users data details  -->

<template>
  <v-row>
    <v-col cols="6">
      <v-card>
        <v-card-title class="d-flex justify-center"
          >Change Password</v-card-title
        >
        <v-card-text>
          <v-form class="form d-flex justify-center">
            <v-row :class="$vuetify.breakpoint.xs ? 'mx-1' : 'ml-8 mr-10'">
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="oldPassword"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min, rules.max]"
                  :type="show2 ? 'text' : 'password'"
                  name="Old Password"
                  class="mt-8 mb-n2"
                  label="Old Password"
                  @click:append="show2 = !show2"
                  :autofocus="!$vuetify.breakpoint.smAndDown"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  class="mt-n2 mb-n2"
                  outlined
                  dense
                  v-model="newPassword"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min, rules.max]"
                  :type="show1 ? 'text' : 'password'"
                  name="newPassword"
                  label="Enter New Password"
                  @click:append="show1 = !show1"
                  :class="$vuetify.breakpoint.xs ? 'border_radius' : ''"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  class="mt-n2 mb-n2"
                  outlined
                  dense
                  v-model="confirmPassword"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min, rules.max]"
                  :type="show1 ? 'text' : 'password'"
                  name="newPassword"
                  label="Confrim New Password"
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <div class="my-3">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              width="107"
              color="#390c2e"
              class="saveBtn white--text"
              @click="setPassword()"
              :disabled="isFormNotValid"
            >
              save
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-col>
    <Snackbar
      :snackbar.sync="snackbar"
      :message="message"
      :color="snackbarColor"
    />
  </v-row>
</template>
<!------------------------------------------------Script-------------------------------------------------->
<script>
import { mapActions, mapGetters } from "vuex";
import Snackbar from "@/components/Snacbar";
export default {
  components: {
    Snackbar,
  },

  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      show1: false,
      show2: false,
      snackbar: false,
      message: "",
      snackbarColor: "",
      rules: {
        required: (value) => !!value || "Required.",
        minName: (v) => v.length >= 3 || "Min 3 characters",
        maxName: (v) => v.length <= 50 || "Max 50 characters",
        min: (v) => v.length >= 6 || "Min 6 characters",
        max: (v) => v.length <= 128 || "Max 128 characters",
        email: (v) =>
          /[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$/.test(v) ||
          "E-mail must be valid",
      },
    };
  },
  computed: {
    isFormNotValid() {
      return (
        this.oldPassword.length < 6 ||
        this.newPassword.length < 6 ||
        this.confirmPassword.length < 6 ||
        this.confirmPassword != this.newPassword
      );
    },
  },
  methods: {
    ...mapActions(["changePassword"]),
    setPassword() {
      var requestObj = {
        current_password: this.oldPassword,
        new_password: this.newPassword,
        confirm_new_password: this.confirmPassword,
      };
      this.changePassword(requestObj).then(
        (response) => {
          if (response.status == 200) {
            this.snackbar = true;
            this.message = "Password changed";
            this.snackbarColor = "success";
          }
        },
        (error) => {
          this.addAdminModal = false;
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            this.snackbar = true;
            this.message = error.response.data.error;
            this.snackbarColor = "error";
          } else {
            this.snackbar = true;
            this.message = "Something went wrong.";
            this.snackbarColor = "error";
          }
        }
      );
    },
  },
};
</script>
<!--------------------------------------------Style------------------------------------------------>
<style scoped>
.v-tab.v-tab--active {
  background-color: #432662 !important;
  border-radius: 5px;
  color: white !important;
  border-bottom: 2px solid white !important;
  border: none !important;
  text-decoration: none !important;
}
.v-tab {
  background-color: white;
  border-radius: 4px 4px 0 0 !important;
  color: #797593 !important;
  text-decoration: none !important;
  max-height: 40px !important;
  min-width: 112px !important;
}

.user-table-email-color {
  color: #a899c4;
  font-size: 14px;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.v-data-footer {
  display: flex !important;
  justify-content: space-between !important;
}
.v-data-table {
  border-radius: 0px;
}
.table-text-color--text {
  color: #17123b;
  font-size: 14px;

  font-weight: bold;
}
#pagination .v-pagination .v-pagination__item,
.v-pagination .v-pagination__more,
.v-pagination {
  height: 21px !important;
  min-width: 20px !important;
  font-size: 12px !important;
}

#pagination .v-pagination__navigation .v-icon {
  font-size: 16px !important;
}
#pagination .v-pagination__navigation .v-pagination__item {
  font-size: 16px !important;
  height: 20px;
  width: 20px;
  color: #eeeff7;
}

#pagination .v-pagination__navigation {
  height: 21px !important;

  width: 20px !important;
}

.v-pagination__item .v-pagination__item--active {
  height: 12px !important;
}
.v-pagination__navigation {
  height: 12px !important;
  width: 5px !important;
}

.normal-width {
  max-width: 33% !important;
}

.select-per-page {
  box-sizing: border-box;
  height: 27.98px !important;
  max-width: 53px !important;
  border: 1px solid rgba(67, 38, 98, 0.13);
  border-radius: 2px;
  font-size: 12px !important;
  color: rgba(67, 38, 98, 0.13) !important;
}
.tableHeaderBg {
  color: #eeeff7;
}
.saveBtn.v-btn--disabled {
  background: grey !important;
}
</style>
