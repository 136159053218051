<template>
  <div class="pa-10">
    <setting />
  </div>
</template>
<script>
import setting from "@/components/setting.vue";
export default {
  components: {
    setting,
  },
  data() {
    return {};
  },
};
</script>
<style></style>
